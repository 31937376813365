/* 220405 Tab Container */
.Tabs {
    width: 80%;
    justify-content: center;
    height: auto;
    min-height: 40px;
    background: #053742;
    margin: 2rem auto 2rem;
    padding: 1rem;
    color: #E8F0F2;
    border-radius: 1rem;
    @media (max-width: 769px) {
      padding: 2rem 0;
    }
  
  }
  
  /* Tab Navigation */
  ul.nav {
    width: 90%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #39A2DB;
    border-radius: 2rem;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  
  ul.nav li {
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 2rem;
  }
  
  ul.nav li:hover {
    background: rgba(50, 224, 196, 0.15);
  }
  ul.nav li.active {
    background: rgba(0,158,213,255);
  }
  
  .SignBasic p,
  .SignEmail p,
  .SignPhone p {
    font-size: 2rem;
    text-align: center;
  }
  