body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*background-color: rgba(51,73,138,255);*/
  /*background-color: rgba(0,158,213,255);*/
  /*background-color: rgba(250,250,250,255);*/
  background-color: #1a2a2a;
  overflow-x: hidden; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

thead {
  background-color: rgba(51,73,138,255);
  color:azure;
}

th {
  vertical-align: middle;
  /*justify-content: center;*/
}

th:hover {
  background-color: rgba(0,158,213,255);
}

.navbar-custom {
  /*background-color: rgba(51,73,138,255);*/
  color:rgba(0,158,213,255);
  font-weight: bold;
  font-size: 1.5rem;
}

.NavLink {
  text-align: center;
}

nav a:hover {
  background-color: rgba(0,158,213,255);
  color: rgba(51,73,138,255);
}